<template>
	<div class="creative">
		<section class="creative-top-block pt-4 px-8 xl:px-0">
			<div class="creative-content w-full h-full relative hidden lg:block">
				<div
					class="creative-top w-full h-full"
					v-if="slider?.images?.length > 0"
				>
					<swiper
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:autoplay="{
  'delay': 5000,
'disableOnInteraction': false
}"
						@swiper="setSwiper"
						@slideChange="onSlideChange"
					>

						<swiper-slide
							v-for="image in slider?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>

					</swiper>
					<template v-if="slider?.images?.length > 1">
						<div class="container relative mx-auto">
							<bullets
								:count="slider?.images?.length"
								:activeIndex="activeIndex"
								color="orange"
								@bulletIndex="slideTo"
								class="flex absolute right-8 -bottom-6 z-10 w-full justify-end"
							></bullets>
						</div>
					</template>
					<!-- <img
						:src="slider?.images[0]?.url"
						:alt="slider?.title"
						class="hidden md:block"
					> -->
				</div>
			</div>
			<div class="page-top mx-auto h-full flex flex-col justify-between lg:hidden">
				<img
					class="mobile-top block lg:hidden mx-auto"
					src="../assets/img/mobile-creative-top.png"
					alt=""
				>
				<img
					class="mobile-title block lg:hidden mx-auto"
					src="../assets/img/mobile-creative-title.svg"
					alt=""
				>
			</div>
		</section>
		<section class="creative-bottom-block pt-12 lg:pt-36 px-0 md:px-8 xl:px-0">
			<div class="creative-menu-blocks grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-3 lg:grid-rows-2 lg:grid-cols-5 gap-4 w-full h-full container mx-auto">
				<template
					v-for="(item, index) in plan.items"
					:key=item.id
				>
					<div class="menu-block w-full h-auto lg:h-full p-4">
						<router-link
							:to="item.name"
							@click="scrollToTop"
							class="lg:h-full lg:flex lg:flex-col lg:justify-between"
						>
							<div class="block-cover pb-4">
								<img
									:src="item.coverImage.url"
									alt=""
								>
							</div>
							<div class="block-content flex flex-col justify-end items-center font-header pt-8 lg:pt-0">
								<div class="text-center sm:text-left">
									<h2 class="hidden lg:block text-sm leading-none">盧經堂 台灣首富之家<br />-</h2>
									<h1
										v-html="item.contents"
										class="hidden lg:block text-xl sm:text-lg lg:text-md"
									></h1>
									<img
										:src="item.images[0].url"
										alt=""
										class="lg:hidden title-img"
									>

								</div>
							</div>
						</router-link>
					</div>
					<div
						class="menu-block-empty w-full h-full hidden lg:block"
						:class="`menu-block-empty-${index}`"
					></div>
				</template>

			</div>
		</section>
	</div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { fetchData } from "../utils/fetchApi";
import SwiperCore, {
	Navigation,
	Pagination,
	Autoplay,
	Controller,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Bullets from "../components/Bullets.vue";

SwiperCore.use([Navigation, Pagination, Autoplay, Controller]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		Bullets,
	},
	setup() {
		const store = useStore();
		const enabledMenuId = store.state.enabledMenuId;

		const sliderId = ref(null);
		const contentId = ref(null);
		const planId = ref(null);

		const slider = reactive({});
		const content = reactive({});
		const plan = reactive({});

		const idTitleObjArray = [
			{ id: sliderId, title: "Cultural and Creative Slider" },
			{ id: contentId, title: "Cultural and Creative Content" },
			{ id: planId, title: "Cultural and Creative Products List" },
		];

		// set swiper instances ref
		const topSwiper = ref(null);

		// set @swiper instances
		const setSwiper = (swiper) => (topSwiper.value = swiper);

		// set Bullets slide to @bulletsIndex
		const slideTo = (index) => {
			topSwiper.value.slideTo(index);
		};

		// set current slide index on <swiper>
		const activeIndex = ref(0);
		const onSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeIndex.value = swiper.activeIndex;
		};

		const scrollToTop = () => window.scrollTo(0, 0);

		onMounted(async () => {
			await fetchData.menuBlocks(enabledMenuId, idTitleObjArray);

			await fetchData.slider(sliderId, slider);
			await fetchData.content(contentId, content);
			await fetchData.priceList(planId, plan);
		});

		return {
			slider,
			content,
			plan,
			scrollToTop,
			setSwiper,
			slideTo,
			activeIndex,
			onSlideChange,
		};
	},
};
</script>

<style lang="scss" scoped>
.creative {
	&-top-block {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@media (max-width: 1023px) {
				height: auto;
				object-fit: contain;
			}
		}
	}
	&-bottom-block {
		width: 100%;
		height: 100vh;
		@media (max-width: 1024px) {
			height: 100%;
		}
	}
}
.menu-block-empty {
	&-0,
	&-1 {
		background: url("../assets/img/creative-menu-block-yellow.png") center
			no-repeat;
		object-fit: cover;
	}
	&-2,
	&-3,
	&-4 {
		background: url("../assets/img/creative-menu-block-red.png") center
			no-repeat;
		object-fit: cover;
	}
}
.block {
	&-cover {
		width: 100%;
		height: 75%;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

	}
	&-content {
		width: 100%;
		height: auto;
        img {
            width: 100%;
        }
        .title-img {
            max-height: 45px;
        }
		@apply leading-normal text-left tracking-wider;
		@media (max-width: 1023px) {
			height: auto;
		}
		h1 {
			@apply font-bold;
		}
		h2 {
			// margin-bottom: 0.5rem;
			@apply text-about;
		}
	}
}
.swiper-container {
	height: calc(100vh - 17rem);
}
</style>
<style lang="scss">
.block-content {
	h1 {
		small {
			@apply text-sm font-normal;
		}
		strong {
			@apply text-about;
		}
	}
}
</style>